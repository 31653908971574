import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  return (
    <FloatingWhatsApp
      phoneNumber="6281367364350"
      accountName="MJ RENT"
      avatar="whatsapp.png"
      allowClickAway
      notification
      notificationSound
      statusMessage="Online"
      chatMessage="Are you ready to rent a bike??"
    />
  );
}

export default App;
